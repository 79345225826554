/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.editedValue[data-v-5b1950e1] {
  border: 2px solid lightblue !important;
  background: #f3f9fb !important;
}
.toBeDeleted[data-v-5b1950e1] {
  border: 2px solid pink !important;
  background: #fff8f9 !important;
}
.newValue[data-v-5b1950e1] {
  border: 2px solid orange !important;
  background: floralwhite !important;
}
.text-input-wrapper[data-v-5b1950e1] {
  position: relative;
}
.formattedValue[data-v-5b1950e1] {
  position: absolute;
  font-size: 12px;
  top: 4px;
  bottom: 4px;
  left: 0px;
  right: 0px;
  border-radius: 2px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: white;
  cursor: text;
  border: 2px solid white;
}
input[data-v-5b1950e1] {
  border: none;
  padding: 5px;
  border: 2px solid #ccc;
  border-radius: 2px;
  text-align: right;
  width: 100%;
}
input[data-v-5b1950e1]:focus {
  outline: none;
  border-color: #e93f6f;
}
.disable-future[data-v-5b1950e1] {
  opacity: 0.5;
  background: grey;
  cursor: default;
}